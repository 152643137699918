import { extendTheme } from "@chakra-ui/react";

export default extendTheme({
  breakpoints: {
    sm: "700px",
    md: "810px",
    lg: "850px",
    xl: "1000px",
    "2xl": "1150px",
  },
});
