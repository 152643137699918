import { COMPANY_SLUG_WHEN_IN_BROWSER } from "utils/appConstants";

const initializeWidgetConfig = (): WidgetConfigReturn => {
  if (process.env.NODE_ENV === "test") {
    return {
      companySlug: "bushelsalesdemo",
      commodityDisplayOrder: [],
    };
  } else {
    const queryParams = new URLSearchParams(window.location.search);
    // Error thrown when both no companySlug is specified and when inside an <iframe/>
    if (
      !queryParams.get("companySlug") &&
      process.env.NODE_ENV !== "development"
    )
      throw new Error("Must specify companySlug in order to use widget");

    return {
      companySlug: queryParams.get("companySlug")
        ? queryParams.get("companySlug")
        : COMPANY_SLUG_WHEN_IN_BROWSER,
      commodityDisplayOrder: queryParams
        .getAll("priorityCommodity")
        .filter((value) => value),
    };
  }
};

export default initializeWidgetConfig;
