import { ReactElement, useEffect, useState, UIEvent } from "react";
import StateHandler from "components/StateHandler/StateHandler";
import LocationSelector from "components/LocationSelector";
import CommodityTable from "components/CommodityTable";
import LocationFooter from "components/LocationFooter";
import LocationEmptyState from "components/LocationEmptyState";
import useStateHandler from "hooks/useStateHandler";
import { CashBidsService } from "services/CashBidsService";
import {
  filterByLocation,
  filterNoCashBids,
  sortCommodities,
} from "utils/filters";
import initializeWidgetConfig from "utils/initializeWidgetConfig";
import { ALL_LOCATIONS_OPTION } from "utils/appConstants";
import {
  convertDateToCentralTime,
  createLocationSelectOptionArray,
  locationToSelectOption,
} from "utils/mappers";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  ExpandedIndex,
  Icon,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { IoTimeOutline } from "react-icons/io5";

const LocationList = (): ReactElement => {
  const [locations, setLocations] = useState<Array<CommodityLocation>>([]);
  const [openLocations, setOpenLocations] = useState<ExpandedIndex>([0]);
  const [lastUpdated, setLastUpdated] = useState<Date>(new Date());
  const [selectedLocation, setSelectedLocation] = useState<SelectOption>({
    value: "",
    label: "",
  });
  const [widgetConfig, setWidgetConfig] = useState<WidgetConfigReturn>({
    companySlug: undefined,
    commodityDisplayOrder: [],
  });
  const [displayShadow, setDisplayShadow] = useState(false);
  const [filteredLocations, setFilteredLocations] = useState<
    Array<CommodityLocation>
  >([]);

  const { setLoading, setError, ...stateHandlerProps } = useStateHandler();

  useEffect(() => {
    try {
      setWidgetConfig(initializeWidgetConfig());
    } catch (error: any) {
      console.error(error.message);
      setLoading(false);
      setError(error.message);
    }
  }, [setError, setLoading]);

  useEffect(() => {
    if (!!widgetConfig.companySlug) {
      CashBidsService.getCashBids(widgetConfig.companySlug)
        .then((data) => {
          if (!data.data && !data.meta)
            throw new Error(
              "No data returned. You likely have an invalid companySlug defined."
            );
          setLocations(data.data);
          setLastUpdated(new Date(data.meta.last_updated));
          setSelectedLocation(locationToSelectOption(data.data[0]));
          setLoading(false);
        })
        .catch((error) => {
          console.error(error.message);
          setLoading(false);
          setError(error.message);
        });
    }
  }, [setError, setLoading, widgetConfig]);

  useEffect(() => {
    if (selectedLocation.value === ALL_LOCATIONS_OPTION.value)
      setFilteredLocations(locations);
    else
      setFilteredLocations(filterByLocation(locations, selectedLocation.value));
  }, [locations, selectedLocation]);
  const [under500] = useMediaQuery("(max-width: 500px)");

  return (
    <StateHandler {...stateHandlerProps}>
      <Box
        border="1px solid"
        borderColor="gray.300"
        boxSizing="border-box"
        borderRadius="2xl"
        pt={4}
      >
        <Box
          boxShadow={
            displayShadow ? "0px 3px 4px rgba(45, 55, 72, 0.2)" : "none"
          }
          zIndex={1}
          position="relative"
          px={[4, 6]}
          pb={4}
          display={under500 ? "block" : "flex"}
          flexDir="row"
          justifyContent="space-between"
        >
          <LocationSelector
            options={createLocationSelectOptionArray(locations)}
            onChange={(newValue) => {
              if (!!newValue) {
                setOpenLocations([0]);
                setSelectedLocation(newValue);
              }
            }}
            defaultValue={selectedLocation}
          />
          <Box
            display="flex"
            alignItems="center"
            color="gray"
            fontFamily="Roboto, sans-serif"
            fontStyle="normal"
            fontWeight="normal"
            fontSize="sm"
            pt={under500 ? 4 : 0}
          >
            <Icon as={IoTimeOutline} h={4} w={4} />
            <Text ml={3.5}>
              Last Updated {convertDateToCentralTime(lastUpdated)}
            </Text>
          </Box>
        </Box>
        <Box
          overflowY="auto"
          h="calc(100vh - 210px)"
          pt={4}
          px={[4, 6]}
          onScroll={(event: UIEvent<HTMLDivElement>) => {
            if (displayShadow && event.currentTarget.scrollTop === 0)
              setDisplayShadow(false);
            else if (!displayShadow && event.currentTarget.scrollTop !== 0)
              setDisplayShadow(true);
          }}
        >
          <Accordion
            index={openLocations}
            allowMultiple
            onChange={(expandedIndexs: ExpandedIndex) => {
              setOpenLocations(expandedIndexs);
            }}
          >
            {filteredLocations.map((location) => {
              const priorityCommodityArray = sortCommodities(
                filterNoCashBids(location.crops),
                widgetConfig.commodityDisplayOrder
              );

              return (
                <AccordionItem
                  key={location.location_id}
                  display="flex"
                  flexDir="column"
                  borderWidth="1px"
                  borderStyle="solid"
                  borderColor="gray.300"
                  boxSizing="border-box"
                  borderRadius="lg"
                  fontFamily="Roboto, sans-serif"
                  fontSize="md"
                  fontStyle="normal"
                  color="black"
                  mb={6}
                  data-testid="location-container"
                >
                  <AccordionButton
                    fontWeight="bold"
                    fontSize="xl"
                    py={2}
                    pl={6}
                    _expanded={{
                      borderBottom: "1px solid",
                      borderColor: "gray.300",
                    }}
                  >
                    <Box flex="1" textAlign="left">
                      {location.location_name}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel pt="44px" pb={0} px={0}>
                    {priorityCommodityArray.length > 0 ? (
                      priorityCommodityArray.map((commodity, index) => (
                        <CommodityTable
                          key={commodity.id}
                          commodity={commodity}
                          lastTable={
                            priorityCommodityArray.length === index + 1
                          }
                        />
                      ))
                    ) : (
                      <LocationEmptyState />
                    )}
                  </AccordionPanel>
                </AccordionItem>
              );
            })}
          </Accordion>
        </Box>
      </Box>
      <LocationFooter />
    </StateHandler>
  );
};

export default LocationList;
