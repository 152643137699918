import { ReactElement } from "react";
import {
  Select,
  ChakraStylesConfig,
  OptionProps,
  chakraComponents,
  useChakraSelectProps,
} from "chakra-react-select";
import Checkmark from "components/Checkmark";
import { StateManagerProps } from "react-select/dist/declarations/src/useStateManager";

const customComponents = {
  Option: ({ ...props }: OptionProps<SelectOption, false>) => (
    <chakraComponents.Option {...props}>
      {props.isSelected ? <Checkmark /> : ""}
      {props.data.label}
    </chakraComponents.Option>
  ),
};

const customStyles: ChakraStylesConfig<SelectOption, false> = {
  container: (provider) => ({
    ...provider,
    fontFamily: "Roboto, sans-serif",
    fontSize: "16px",
    fontStyle: "normal",
    width: ["250px", "403px"],
    height: "40px",
  }),
  control: (provider) => ({
    ...provider,
    paddingLeft: "16px",
    borderRadius: "8px",
  }),
  indicatorSeparator: (provider) => ({
    ...provider,
    display: "none",
  }),
  menuList: (provided) => ({
    ...provided,
  }),
  option: (provider, state) => ({
    ...provider,
    paddingLeft: state.isSelected ? "0" : "30px",
    display: "flex",
    alignItems: "center",
  }),
  dropdownIndicator: (provider) => ({
    ...provider,
    backgroundColor: "white",
  }),
};

const LocationSelector = (
  props: StateManagerProps<SelectOption, false>
): ReactElement => {
  const selectProps = useChakraSelectProps({
    onChange: props.onChange,
    defaultValue: props.defaultValue,
    options: props.options,
  });

  return (
    <Select
      {...selectProps}
      chakraStyles={customStyles}
      components={customComponents}
    />
  );
};

export default LocationSelector;
