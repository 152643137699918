import { ReactElement } from "react";
import NO_CASH_BIDS from "img/no-cash-bids.png";
import { Box, Image, Text } from "@chakra-ui/react";

const LocationEmptyState = (): ReactElement => (
  <Box display="flex" flexDir="column" alignItems="center" py="44px">
    <Image w="198px" h="106px" alt="No Cash Bids" src={NO_CASH_BIDS} />
    <Box
      w="328px"
      display="flex"
      flexDir="column"
      alignItems="center"
      pt={6}
      textAlign="center"
    >
      <Text fontSize="xl" fontWeight="bold" py={4}>
        No Cash Bids
      </Text>
      <Text lineHeight="base">
        There are no Cash Bids to show, please refresh or try again later.
      </Text>
    </Box>
  </Box>
);

export default LocationEmptyState;
