import { ALL_LOCATIONS_OPTION } from "./appConstants";

export const filterByLocation = (
  locations: Array<CommodityLocation>,
  filterValue: string
) => {
  if (filterValue === ALL_LOCATIONS_OPTION.value) return locations;
  return locations.filter((location) => location.location_name === filterValue);
};

export const filterNoCashBids = (commodities: Array<Commodity>) =>
  commodities.filter((commodity) => commodity.bids.length > 0);

export const sortCommodities = (
  commodities: Array<Commodity>,
  priorityCommodities: Array<string>
) => {
  if (priorityCommodities.length === 0) return commodities;
  const lowerCasePriorityCommodites = priorityCommodities.map((item) =>
    item.toLowerCase()
  );
  const filteredPriorityCommodities = commodities
    .filter((commodity) => {
      return lowerCasePriorityCommodites.includes(commodity.name.toLowerCase());
    })
    .sort(
      (a, b) =>
        lowerCasePriorityCommodites.indexOf(a.name.toLowerCase()) -
        lowerCasePriorityCommodites.indexOf(b.name.toLowerCase())
    );
  const filteredNonPriorityCommodities = commodities.filter(
    (commodity) =>
      !lowerCasePriorityCommodites.includes(commodity.name.toLowerCase())
  );

  return [...filteredPriorityCommodities, ...filteredNonPriorityCommodities];
};
