import { BASE_URL, CASH_BIDS_ENDPOINT } from "./constants";

export class CashBidsService {
  static getCashBids(
    companySlug: string
  ): Promise<ApiResponse<Array<CommodityLocation>>> {
    const requestURL = `${BASE_URL}${CASH_BIDS_ENDPOINT}`;
    const headers = new Headers({
      "app-company": companySlug,
    });

    return fetch(requestURL, {
      headers,
    }).then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      } else return response.json();
    });
  }
}
