import { ReactElement } from "react";
import ICE_LOGO from "img/ice.png";
import CME_GROUP_LOGO from "img/cme-group-logo.png";
import POWERED_BY_BUSHEL_LOGO from "img/powered-by-bushel-logo.png";
import { Box, Image, Stack, Text, useMediaQuery } from "@chakra-ui/react";

const LocationFooter = (): ReactElement => {
  const [under380] = useMediaQuery("(max-width: 380px)");
  return (
    <Stack
      direction={["column", "column", "row"]}
      spacing={[4, 4, 12]}
      color="gray"
      fontFamily="Roboto, sans-serif"
      fontStyle="normal"
      fontWeight="normal"
      fontSize="sm"
      px={8}
      py={4}
      alignItems={["center", "center", "flex-start"]}
    >
      <Text flex={3} overflowWrap="normal">
        Market data provided by Bushel. Information is provided &apos;as
        is&apos; and solely for informational purposes, not for trading purposes
        or advice, and is delayed per exchange requirements.
      </Text>
      <Box
        display={under380 ? "block" : "flex"}
        alignItems={under380 ? "start" : "center"}
      >
        <Image
          boxSize={4}
          mr={4}
          alt="ICE logo"
          src={ICE_LOGO}
          mb={under380 ? 4 : 0}
        />
        <Image
          h={4}
          w="96px"
          mr={4}
          alt="CME Group Logo"
          src={CME_GROUP_LOGO}
          mb={under380 ? 4 : 0}
        />
        <Image
          h={4}
          alt="Powered By Bushel"
          src={POWERED_BY_BUSHEL_LOGO}
          mb={under380 ? 4 : 0}
        />
      </Box>
    </Stack>
  );
};

export default LocationFooter;
