import { ALL_LOCATIONS_OPTION, SYMBOL_CODES } from "./appConstants";

export const locationToSelectOption = (
  location: CommodityLocation
): SelectOption => {
  return { value: location.location_name, label: location.location_name };
};

export const createLocationSelectOptionArray = (
  locations: CommodityLocation[]
) => {
  const selectableLocations = locations.map(locationToSelectOption);
  return [ALL_LOCATIONS_OPTION, ...selectableLocations];
};

export const convertDateToCentralTime = (date: Date): string => {
  const options: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric",
    timeZone: "America/Chicago",
    timeZoneName: "short",
  };
  return date.toLocaleString("en-US", options);
};

export const convertCommoditySymbolToFuturesMonth = (
  cashBidSymbol: string
): string => {
  const commodityMonth = SYMBOL_CODES.monthCode.find(
    (monthCode) => monthCode.code === cashBidSymbol?.slice(2, 3)
  )?.month;
  const commodityYear = cashBidSymbol?.slice(3, 5);
  return commodityMonth && commodityYear ? commodityMonth + commodityYear : "-";
};
