import { Dispatch, SetStateAction, useState } from "react";

type UseStateHandlerReturn = {
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  error: string;
  setError: Dispatch<SetStateAction<string>>;
};

const useStateHandler = (): UseStateHandlerReturn => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  return {
    loading,
    setLoading,
    error,
    setError,
  };
};

export default useStateHandler;
