import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const prepare = async () => {
  // conditionall start MSW if desired functionality is to return mock data
  if (
    process.env.NODE_ENV === "development" &&
    process.env.REACT_APP_USE_MOCK_SERVICE_WORKER === "true"
  ) {
    const { worker } = await import("./mocks/browser");
    return worker.start({ onUnhandledRequest: "bypass" });
  }

  return Promise.resolve();
};

prepare().then(() => {
  const container = document.getElementById("root");
  if (!container) throw new Error("Failed to find the root element");
  createRoot(container).render(<App />);
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
