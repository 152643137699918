import { ReactElement } from "react";
import { IoCaretUp, IoCaretDown } from "react-icons/io5";
import { Box, Icon } from "@chakra-ui/react";

const FuturesChangeCell = ({
  changeValue,
}: {
  changeValue: string;
}): ReactElement => {
  if (!changeValue) {
    return (
      <Box display="flex" alignItems="center" justifyContent="flex-end">
        -
      </Box>
    );
  } else {
    const valueIsGreaterThanZero = Number(changeValue) > 0;
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        color={valueIsGreaterThanZero ? "green" : "red"}
      >
        {valueIsGreaterThanZero ? (
          <Icon mr={2} as={IoCaretUp} />
        ) : (
          <Icon mr={1} as={IoCaretDown} />
        )}
        {changeValue}
      </Box>
    );
  }
};

export default FuturesChangeCell;
