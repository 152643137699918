import FuturesChangeCell from "components/FuturesChangeCell";
import { ReactElement, useCallback, useMemo, useState } from "react";
import { Column, Row, useFlexLayout, useTable } from "react-table";
import { convertCommoditySymbolToFuturesMonth } from "utils/mappers";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Button,
  useMediaQuery,
} from "@chakra-ui/react";

const buttonDisplayText = (showMoreRows: boolean): string =>
  showMoreRows ? "Show less" : "Show all cash bids";

const CommodityTable = ({
  commodity,
  lastTable,
}: {
  commodity: Commodity;
  lastTable: boolean;
}): ReactElement => {
  const [showMoreRows, setShowMoreRows] = useState(false);
  const [width] = useMediaQuery("(min-width: 700px)");
  const [under540] = useMediaQuery("(max-width: 540px)");
  const [under380] = useMediaQuery("(max-width: 380px)");
  const [under320] = useMediaQuery("(max-width: 320px)");
  const bottomPaddingOnShowMoreButton = lastTable ? "16px" : "46px";
  const shouldHideFutures = under540;
  const shouldHideMonth = under540;
  const shouldHideChange = under320;

  // must be memoized
  const data: Array<CashBid> = useMemo(() => commodity.bids, [commodity]);

  // must be memoized
  const columns = useMemo<Array<Column<CashBid>>>(
    () => [
      {
        Header: "Delivery",
        accessor: "description",
        width: width ? 130 : under380 ? 80 : 100,
      },
      {
        Header: "Bid",
        accessor: "current_bid",
        width: width ? 90 : 60,
      },
      {
        Header: "Basis",
        accessor: "basis_price",
        Cell: ({ value }) => <>{value ?? "-"}</>,
        width: width ? 90 : 50,
      },
      {
        Header: "Futures",
        accessor: "futures_price",
        Cell: ({ value }) => <>{value ?? "-"}</>,
        width: shouldHideFutures ? 0 : width ? 90 : 80,
      },
      {
        Header: "Change",
        accessor: "futures_change",
        Cell: ({ value }) => <FuturesChangeCell changeValue={value} />,
        width: shouldHideChange ? 0 : width ? 100 : 95,
      },
      {
        Header: "Month",
        accessor: "symbol",
        Cell: ({ value }) => <>{convertCommoditySymbolToFuturesMonth(value)}</>,
        width: shouldHideMonth ? 0 : width ? 100 : 80,
      },
    ],
    [width, shouldHideFutures, shouldHideMonth, under380, shouldHideChange]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useFlexLayout
    );

  const displayRow = useCallback(
    (row: Row<CashBid>): ReactElement => {
      const isLastRowWithNoShowMoreButton =
        lastTable && row.index + 1 === rows.length && rows.length < 6;
      const tableRowStyles = isLastRowWithNoShowMoreButton
        ? {
            borderBottom: "none",
            borderBottomLeftRadius: "lg",
            borderBottomRightRadius: "lg",
            borderColor: "none",
          }
        : {
            borderBottom: "1px solid",
            borderBottomLeftRadius: "none",
            borderBottomRightRadius: "none",
            borderColor: "gray.300",
          };

      prepareRow(row);
      row.cells = row.cells.filter((cell) => cell.column.width != 0);
      return (
        /* the jsx key is provided in the .get*Props() spreads. */
        // eslint-disable-next-line react/jsx-key
        <Tr
          {...row.getRowProps()}
          background={row.index % 2 === 0 ? "white" : "gray.50"}
          {...tableRowStyles}
        >
          {row.cells.map((cell, index) => {
            const lastCell = index === row.cells.length - 1;
            const firstCell = index === 0;
            const firstOrLastCell = firstCell || lastCell;
            return (
              // eslint-disable-next-line react/jsx-key
              <Td
                {...cell.getCellProps()}
                textAlign={firstCell ? "left" : "right"}
                py={1.5}
                pr={under380 ? [1, 2, 4] : lastCell ? [1, 2, 4] : 0}
                pl={under380 ? [1, 2, 4] : firstOrLastCell ? [4, 8, 12] : 0}
                whiteSpace="nowrap"
                textOverflow="ellipsis"
                overflow="hidden"
                title={cell.value}
              >
                {cell.render("Cell")}
              </Td>
            );
          })}
        </Tr>
      );
    },
    [lastTable, prepareRow, rows.length, under380]
  );

  return (
    <Box
      display="flex"
      alignItems="center"
      flexDir="column"
      pb={rows.length > 5 || lastTable ? 0 : "44px"}
    >
      <Table {...getTableProps()}>
        <Thead>
          <Tr>
            <Th
              fontWeight="semibold"
              fontSize="md"
              textTransform="none"
              fontFamily="Roboto, sans-serif"
              letterSpacing="none"
              pt={0}
              pb={2}
              pl={[4, 6, 8]}
              border="none"
              color="black"
              title={commodity.name}
            >
              {commodity.name}
            </Th>
          </Tr>
          {headerGroups.map((headerGroup) => {
            headerGroup.headers = headerGroup.headers.filter(
              (header) => header.width != 0
            );
            return (
              // eslint-disable-next-line react/jsx-key
              <Tr
                {...headerGroup.getHeaderGroupProps()}
                borderBottom="1px solid"
                borderColor="gray.300"
                fontSize="xs"
                fontWeight="bold"
                textTransform="uppercase"
                letterSpacing="0.08em"
              >
                {headerGroup.headers.map((column, index) => {
                  const lastHeader = index === headerGroup.headers.length - 1;
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <Th
                      {...column.getHeaderProps()}
                      color="#718096"
                      py={3}
                      pr={under380 || lastHeader ? [1, 2, 4] : 0}
                      pl={under380 ? [1, 2, 4] : [4, 8, 12]}
                      display="flex"
                      justifyContent={index === 0 ? "left" : "right"}
                      alignItems="center"
                      whiteSpace="nowrap"
                      textOverflow="ellipsis"
                      overflow="hidden"
                    >
                      {column.render("Header")}
                    </Th>
                  );
                })}
              </Tr>
            );
          })}
        </Thead>
        <Tbody {...getTableBodyProps()} fontWeight="normal">
          {showMoreRows
            ? rows.map(displayRow)
            : rows.slice(0, 5).map(displayRow)}
        </Tbody>
      </Table>
      {rows.length > 5 ? (
        <Button
          variant="link"
          mt={4}
          mb={bottomPaddingOnShowMoreButton}
          w={133}
          color="blue.500"
          fontWeight="normal"
          textDecor="underline"
          onClick={() => {
            setShowMoreRows(!showMoreRows);
          }}
        >
          {buttonDisplayText(showMoreRows)}
        </Button>
      ) : null}
    </Box>
  );
};
export default CommodityTable;
