import { ReactElement } from "react";
import LocationList from "components/LocationList";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme";

const App = (): ReactElement => (
  <ChakraProvider theme={theme}>
    <LocationList />
  </ChakraProvider>
);

export default App;
