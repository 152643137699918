import { CSSProperties, ReactElement, ReactNode } from "react";
import { Skeleton, Box, Stack, Image, Text, HStack } from "@chakra-ui/react";
import ERROR_STATE from "img/error-state.png";

const CommodityTableSkeleton = (): ReactElement => (
  <Stack pl={4} pt={6} spacing={2}>
    <Skeleton h={5} borderRadius="base" />
    <Skeleton h={5} borderRadius="base" />
    <Skeleton h={5} borderRadius="base" />
    <Skeleton h={5} borderRadius="base" />
    <Skeleton h={5} borderRadius="base" />
  </Stack>
);

const LocationSkeleton = (): ReactElement => (
  <Stack border="1px solid" borderColor="gray.300" borderRadius="lg" pb={4}>
    <Box borderBottom="1px solid" borderColor="gray.300" py={2} pl={6}>
      <Skeleton h={5} w={242} borderRadius="base" />
    </Box>
    <Stack pl={8} pr={12} pt={4} spacing={0}>
      <Skeleton h={5} w={242} borderRadius="base" />
      <CommodityTableSkeleton />
    </Stack>
    <Stack pl={8} pr={12} pt={10} spacing={0}>
      <Skeleton h={5} w={242} borderRadius="base" />
      <CommodityTableSkeleton />
    </Stack>
  </Stack>
);

const LoadingState = (): ReactElement => (
  <Box>
    <Box
      border="1px solid"
      borderColor="gray.300"
      boxSizing="border-box"
      borderRadius="2xl"
      pt={4}
    >
      <HStack px={[4, 6]} mb={4} justifyContent="space-between">
        <Skeleton borderRadius="base" w="39%" h={33} />
        <Skeleton borderRadius="base" w="8%" h="20px" />
      </HStack>
      <Stack
        overflowY="auto"
        h="calc(100vh - 130px)"
        py={4}
        px={[4, 6]}
        spacing={6}
      >
        <LocationSkeleton />
        <LocationSkeleton />
      </Stack>
    </Box>
    <Box display="flex" justifyContent="space-between" pt={3} px={8}>
      <Skeleton h={5} w="60%" borderRadius="base" />
      <Skeleton h={5} w="23%" borderRadius="base" />
    </Box>
  </Box>
);

const ErrorState = ({
  errorProps,
}: {
  errorProps?: CSSProperties;
}): ReactElement => {
  return (
    <Box
      style={{ ...errorProps }}
      display="flex"
      flexDir="column"
      alignItems="center"
      py={195}
      border="1px solid"
      borderColor="gray.300"
      boxSizing="border-box"
      borderRadius="2xl"
    >
      <Image w={400} h={171} alt="Error" src={ERROR_STATE} />
      <Box
        w={300}
        display="flex"
        flexDir="column"
        alignItems="center"
        pt={6}
        textAlign="center"
      >
        <Text fontSize="xl" fontWeight="bold" py={4}>
          Ope, sorry!
        </Text>
        <Text lineHeight="base">
          Sorry, looks like you hit a dead end. Please refresh or try again
          later.
        </Text>
      </Box>
    </Box>
  );
};

const StateHandler = ({
  loading,
  error,
  children,
  errorProps,
}: {
  loading: boolean;
  error: string;
  errorProps?: CSSProperties;
  children: ReactNode;
}): ReactElement => {
  if (loading) return <LoadingState />;
  if (error) return <ErrorState errorProps={errorProps} />;
  return <>{children}</>;
};

export default StateHandler;
