export const ALL_LOCATIONS_OPTION = {
  value: "all-locations",
  label: "All Locations",
};

export const COMPANY_SLUG_WHEN_IN_BROWSER = "bushelsalesdemo";

// See "crops" on https://dev.azure.com/bushelpowered/_git/bushel-trade-futures?path=/src/main/kotlin/ag/bushel/futures/Config.kt
const COMMODITY_CODES: CommodityCode[] = [
  { code: "EH", commodity: "Ethanol" },
  { code: "ZC", commodity: "Corn" },
  { code: "ZS", commodity: "Soybean" },
  { code: "ZL", commodity: "Soybean Oil" },
  { code: "ZM", commodity: "Soybean Meal" },
  { code: "MW", commodity: "Spring Wheat" },
  { code: "ZW", commodity: "Wheat" },
  { code: "KE", commodity: "Hard Red Wheat" },
  { code: "LC", commodity: "Live Cattle" },
  { code: "GF", commodity: "Feeder Cattle" },
  { code: "HE", commodity: "Lean Hogs" },
  { code: "RS", commodity: "Canola" },
  { code: "ZO", commodity: "Oats" },
  { code: "CT", commodity: "Cotton" },
  { code: "ZR", commodity: "Rice" },
];

const MONTH_CODES: MonthCode[] = [
  { code: "F", month: "JAN" },
  { code: "G", month: "FEB" },
  { code: "H", month: "MAR" },
  { code: "J", month: "APR" },
  { code: "K", month: "MAY" },
  { code: "M", month: "JUN" },
  { code: "N", month: "JUL" },
  { code: "Q", month: "AUG" },
  { code: "U", month: "SEP" },
  { code: "V", month: "OCT" },
  { code: "X", month: "NOV" },
  { code: "Z", month: "DEC" },
];

export const SYMBOL_CODES = {
  commodityCode: COMMODITY_CODES,
  monthCode: MONTH_CODES,
};
